import getConfig from 'next/config';
import { Config } from './interfaces';
import staticConfig from './static/config';

const { publicRuntimeConfig } = getConfig();

const environment =
  publicRuntimeConfig?.APP_ENVIRONMENT ||
  process.env.APP_ENVIRONMENT ||
  'development';

const config = {
  environment,
  ...staticConfig,

  // eslint-disable-next-line
  ...require(`${__dirname}/env/${environment}`),
};

export default config as Config;
