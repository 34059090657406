// Segment Analytics Wrapper

export enum ESegmentCategory {
  SELECT_LANG = 'SELECT_LANG',
  VISIT_LANDING_WITH_LEADS = 'VISIT_LANDING_WITH_LEADS',
  VISIT_LANDING_WITH_LEADS_FLEET = 'VISIT_LANDING_WITH_LEADS_FLEET',
  CLICKED_SUBMIT = 'LANDING_CLICKED_SUBMIT',
  LANDING_DRIVER_LEAD = 'LANDING_DRIVER_LEAD',
  LANDING_FLEET_LEAD = 'LANDING_FLEET_LEAD',
  LANDING_DRIVER_SIGN_UP_OPEN_FORM = 'LANDING_DRIVER_OPEN_SIGN_UP_FORM',
  LANDING_FLEET_OPEN_POST_JOBS_FORM = 'LANDING_FLEET_OPEN_POST_JOBS_FORM',
}

type IPageProps = {
  category?: ESegmentCategory;
  name?: string;
  otherProps?: Record<string, unknown>;
};

/**
 * Records whenever a user sees a page on the website
 *
 * More Info: https://segment.com/docs/connections/spec/page/
 */
export const page = ({ category, name, otherProps }: IPageProps) => {
  if (window.analytics) {
    window.analytics.page(category, name, { ...otherProps });
  }
};

/**
 * @param eventName Identification of event
 * @param properties Properties to track
 *
 * More Info: https://segment.com/docs/connections/spec/track/
 */
export const track = (
  eventName: ESegmentCategory,
  properties: Record<string, unknown>
) => {
  if (window.analytics) {
    window.analytics.track(eventName, { ...properties });
  } else {
    const intervalId = setInterval(() => {
      if (window.analytics) {
        window.analytics.track(eventName, { ...properties });
        clearInterval(intervalId);
      }
    }, 100);
  }
};
