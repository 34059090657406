import { Config } from '../interfaces';

const config: Config = {
  baseUrl: 'http://localhost:3003',
  signUpDriver: 'http://localhost:3001/auth/registerAsDriver',
  signInDriver: 'http://localhost:3001/auth/driverLogin/login',
  signInFleet: 'http://localhost:3001/auth/carrierLogin',
  signUpFleet: 'http://localhost:3001/auth/registerAsCarrier',
  apiUrl: 'http://localhost:3000',
  apiKey: process.env.API_KEY,
  intercomAppId: 'mv2qjxgt',
  segmentId: 'LSslS5GieBpLVpUa4KMEcmEMaTBVXzdA',
  launchDarklyClientId: '6261875821114115111c583b',
  googleOptimize: 'OPT-KHM4SRP',
  googleOptimizeTagManager: 'G-8KCT90Q7C6',
  googleOptimizeExperimentId: 'nJ91tT8nQwyxoZU8FLPmYw',
  wpApiHost: 'https://blog.haulwith.us',
};

module.exports = config;
