import NextNprogress from 'nextjs-progressbar';
import { IntercomProvider, useIntercom } from 'react-use-intercom';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { page } from 'services/analytics';

import config from '../config';

import '../styles/globals.scss';

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS; // Segment
  }
}

interface MyAppProps {
  Component: any;
  pageProps: any;
}

// We need to tell intercom every-time the page changes so it can send a "ping" api request.
// We cannot use `useIntercom` hook outside of the IntercomProvider context. That is why this wrapper exists.
// It is inside the IntercomProvider context and listens and reports page changes.
const AppWrapper: React.FC<{ children: JSX.Element[] }> = ({ children }) => {
  const router = useRouter();
  const { update } = useIntercom();

  useEffect(() => {
    const handleRouteChange = () => {
      update();

      page({}); // analytics for client side routing edge cases
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, update]);

  return <>{children}</>;
};

function MyApp({ Component, pageProps }: MyAppProps) {
  return (
    <IntercomProvider appId={config.intercomAppId} autoBoot>
      <AppWrapper>
        <NextNprogress color="#f0ff69" stopDelayMs={400} />
        <Component {...pageProps} />
      </AppWrapper>
    </IntercomProvider>
  );
}

export default withLDProvider({
  clientSideID: config.launchDarklyClientId,
})(MyApp as any);
