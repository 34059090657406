import { Config } from '../interfaces';

const config: Config = {
  baseUrl: 'https://staging.haulwith.us',
  signUpDriver: 'https://staging-fleet.haulwith.us/auth/registerAsDriver',
  signInDriver: 'https://staging-fleet.haulwith.us/auth/driverLogin',
  signInFleet: 'https://staging-fleet.haulwith.us/auth/carrierLogin',
  signUpFleet: 'https://staging-fleet.haulwith.us/auth/registerAsCarrier',
  apiKey: process.env.API_KEY,
  apiUrl: 'https://staging-api.haulwith.us',
  intercomAppId: 'mv2qjxgt',
  segmentId: 'LSslS5GieBpLVpUa4KMEcmEMaTBVXzdA',
  launchDarklyClientId: '626180e6903a3a15617f06d2',
  googleOptimize: 'OPT-KHM4SRP',
  googleOptimizeTagManager: 'G-8KCT90Q7C6',
  googleOptimizeExperimentId: 'ig0aOU62ReWKM-Ato5J_Uw',
  wpApiHost: 'https://blog.haulwith.us',
};

module.exports = config;
