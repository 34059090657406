import { Config } from '../interfaces';

const config: Config = {
  baseUrl: 'https://haulwith.us',
  signUpDriver: 'https://fleet.haulwith.us/auth/registerAsDriver',
  signInDriver: 'https://fleet.haulwith.us/auth/driverLogin/login',
  signInFleet: 'https://fleet.haulwith.us/auth/carrierLogin',
  signUpFleet: 'https://fleet.haulwith.us/auth/registerAsCarrier',
  apiKey: process.env.API_KEY,
  apiUrl: 'https://api.haulwith.us',
  intercomAppId: 'y27brstt',
  segmentId: 'DudozzvtgVDDnfkQCRwWNxejpackdLWy',
  launchDarklyClientId: '626180e6903a3a15617f06d3',
  googleOptimize: 'OPT-TJ57MM8',
  googleOptimizeTagManager: 'G-0THQB0Q615',
  googleOptimizeExperimentId: 'pbe_RhRNR3SJjQ_xNUPjOw',
  wpApiHost: 'https://blog.haulwith.us',
};

module.exports = config;
